<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Asociar a Pago Directo</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Servicio -->
        <vs-input
          disabled="disabled"
          label="Nombre de la Escuela"
          v-model="dataName"
          class="mt-5 w-full"
          name="item-service-name" />
        <span class="text-danger text-sm" v-show="errors.has('item-service-name')">{{ errors.first('item-service-name') }}</span>
        <!-- <vs-alert v-show="dataStatus == 0" style="height: initial" color="danger" class="mt-2" icon-pack="feather" icon="icon-info">
          El servicio se encuentra <strong>inactivo</strong>. <br>
          Si bien puedes editar los datos, este servicio no se mostrará disponible para pagos en la aplicación Tiptap.
        </vs-alert> -->
        <!-- Asociado -->
        <div class="mt-5">
            <label class="vs-input--label w-full">Estado de asociación a Pago Directo</label>
            <vs-switch
                v-model="dataDirectPayment"
                class="w-1/4 my-2"
                >
                <template #off>
                    No Asociado
                </template>
                <template #on>
                    Asociado
                </template>
            </vs-switch>
            <p><span>Para asociar la escuela, deslice la opción a <strong>asociado</strong>. Al hacerlo, los acudientes podrán realizar Pagos Directos a la escuela desde la aplicación móvil <strong>Tiptap</strong>.</span></p>
        </div>



        <!-- Tiptap Commission  -->
        <div class="mt-5 w-full">
          <label class="vs-input--label">Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number
              @input="checkLength($event)"
              label="%"
              v-model="dataDirectPaymentCommission"
              :step="0.25"
              name="tiptap_commission"
              v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"
              data-vv-as="Comisión Tiptap"
            />
            <span class="text-danger text-sm"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { name, status, id_commerce, id_commerce_direct_payment, direct_payment, direct_payment_commission } = JSON.parse(JSON.stringify(this.data))
        this.dataIdCommerce = id_commerce
        this.dataIdCommerceDirectPayment = id_commerce_direct_payment
        this.dataName = name
        this.dataStatus = status
        this.dataDirectPayment = direct_payment
        this.dataDirectPaymentCommission = direct_payment_commission
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  data() {
    return {
      dataIdCommerce: null,
      dataIdCommerceDirectPayment: null,
      dataName: null,
      dataStatus: false,
      dataDirectPayment: 0,
      dataDirectPaymentCommission: 0,

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && (this.data.direct_payment != this.dataDirectPayment || this.data.direct_payment_commission != this.dataDirectPaymentCommission) && (this.dataIdCommerceDirectPayment == null ? this.dataDirectPayment == 1 && this.dataDirectPaymentCommission >= 0 : true)
    },
  },
  methods: {
    initValues() {
        this.dataIdCommerce = null
        this.dataIdCommerceDirectPayment = null
        this.dataName = null
        this.dataStatus = null
        this.dataDirectPayment = 0
        this.dataDirectPaymentCommission = 0
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()

          let obj = { id_commerce: this.dataIdCommerce }
          let action = 'addCommerceToDirectPayment'

          //TODO - Refactor: how to re-add removed commerce from Direct Payment
          if (this.dataIdCommerceDirectPayment == null) {
            // Add commerce to Direct Payment
            obj.tiptap_commission = this.dataDirectPaymentCommission
          } else {
            // Edit or Remove commerce from Direct Payment
            obj.id_commerce_direct_payment = this.dataIdCommerceDirectPayment

            if (this.data.direct_payment == 1 && this.dataDirectPayment == 0) {
              // Removing commerce from Direct Payment
              action = 'removeCommerceFromDirectPayment'
            } else {
              // Edit
              action = 'updateCommerceDirectPayment'
              obj.tiptap_commission = this.dataDirectPaymentCommission
              obj.status = this.dataDirectPayment ? 1 : 0
            }
          }

          this.dispatchAction(action, obj)
        }
      })
    },
    dispatchAction(action, payload) {
      this.$store.dispatch(`directPaymentModule/${action}`, payload)
        .then(response => {
          if (response.status)
            this.$emit('showNotify', 'success', 'Registro Actualizado!', 'Se han guardado los cambios.')
            this.$emit('closeSidebar')
            this.initValues()
            this.$vs.loading.close()
        })
        .catch(err => {
          this.$emit('showNotify', 'danger', 'Error', err)
          this.$vs.loading.close()
        })
    },
    checkLength(value) {
      if(value < 0 || value == '')
        value = 0

      this.dataDirectPaymentCommission = (typeof value == 'string') ? parseFloat(value).toFixed(3) : value.toFixed(3);
    },
  },
  components: {
    VuePerfectScrollbar,
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
