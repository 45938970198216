<template>
  <div id="data-list-list-view" class="data-list-container">
    <subscribe-sidebar :isSidebarActive="subscribeSidebar" @closeSidebar="toggleSidebar" :data="sidebarData" @showNotify="showNotify"/>
    <vs-table ref="table" search :data="commerces">
        <template slot="thead">
            <vs-th sort-key="id">Id</vs-th>
            <vs-th sort-key="name">Servicio</vs-th>
            <!-- <vs-th sort-key="status">Estado del Servicio</vs-th> -->
            <vs-th>Estado de Asociación</vs-th>
            <vs-th sort-key="amount">Comisión del Servicio</vs-th>
            <vs-th>Acciones</vs-th>
        </template>

        <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="font-medium">{{ tr.id_commerce }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.name }}</p>
                </vs-td>

                <!-- <vs-td>
                  <vs-chip :color="getStatusColor(tr.service_status)" class="product-order-status">{{ tr.service_status === 0 ? "Inactivo" : "Activo" }}</vs-chip>
                </vs-td> -->

                <vs-td>
                  <vs-chip :color="getSubscribedColor(tr.direct_payment)" class="product-order-status">{{ tr.direct_payment == 1 ? "Asociado" : "No Asociado" }}</vs-chip>
                  <!-- :class="tr.status ? 'bg-primary text-white' : 'bh-white text-dark'" -->
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.direct_payment_commission ? $options.filters.currencyPercentage(tr.direct_payment_commission, ' %') : '' }}</p>
                </vs-td>

                <!-- <vs-td class="whitespace-no-wrap">
                  <vs-switch
                    v-model="tr.subscribed"
                    class="ml-2"
                    @change="updateServices($event, tr)"
                    :disabled="tr.service_status == 0"
                  >
                    <template #off>
                        No Asociado
                    </template>
                    <template #on>
                        Asociado
                    </template>
                  </vs-switch>
                </vs-td>

                <vs-td :data="tr.amount" >
                  {{ tr.amount ? '$ ' + tr.amount : '' }}
                  <template slot="edit" v-if="tr.service_status != 0">
                    <vs-input label="Ingrese monto fijo" name="monto_fijo" :disabled="tr.subscribed == 0 && !update.includes(tr.id_main_institution)" type="number" icon-pack="feather" icon="icon-dollar-sign" icon-no-border v-model="tr.amount" class="inputx" v-validate="'numeric|max_value:99999|min_value:0'"/>
                  </template>
                </vs-td> -->

                <vs-td class="whitespace-no-wrap">
                    <feather-icon icon="SlidersIcon" svgClasses="w-5 h-5 cursor-pointer hover:text-primary stroke-current" @click.stop="openSidebar(tr)" />
                </vs-td>
            </vs-tr>
        </template>
    </vs-table>
    <!-- <div class="justify-center text-center responsive-fix mt-4">
      <vs-button color="dark" type="flat" :disabled="!update.length" @click="cancelChanges()" icon-after class="shadow-md-3">Cancelar</vs-button>
      <vs-button  :disabled="!update.length" @click="updateServiceSubscription()" icon-after class="shadow-md-3">Guardar</vs-button>
    </div> -->
  </div>
</template>

<script>
import moduleDirectPayment from "@/store/direct-payment/moduleDirectPayment.js"
import subscribeSidebar from './sidebar/SubscribeSidebar.vue'

export default {
  data() {
    return {
      selected: [],
      isMounted: false,
      commerces: [],
      update: [],
      enableSave: false,

      subscribeSidebar: false,
      sidebarData: {},

    }
  },
  components: {
    subscribeSidebar
  },
  methods: {
    updateServiceSubscription() {
      const obj = {
        id_main_institution: this.$store.state.AppActiveUser.institution.id,
        data: this.services.filter(s => this.update.includes(s.id)).map(i => {
          return {
            "id_service": i.id,
            "amount": i.amount,
            "status": i.subscribed ? 1 : 0
          }
        })
      }

      // id_services: this.services.filter(s => s.id_main_institution ).map(i => i.id)
      this.$store.dispatch("servicesModule/updateInstitutionServices", obj)
      .then(response => {
        if(!response.data.status)
          throw new Error(response.data.msg)

        this.initial = JSON.parse(JSON.stringify(this.services))
        this.update = []
        this.showAddSuccess()
      })
      .catch(error => { this.showError(error) })

    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
      })
    },
    getStatusColor(status) {
      if(status === 0) return "danger"
      if(status === 1) return "success"
      return "primary"
    },
    getSubscribedColor(status) {
      if(status === 1) return "primary"
      return ""
    },
    showError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo completar el proceso.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Servicio',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    updateServices(e, data) {
      if(e.type == 'change') {
        if(this.update.indexOf(data.id) >= 0)
          this.update.splice(this.update.indexOf(data.id), 1)
        else
          this.update.push(data.id)
      }
    },
    cancelChanges() {
      this.commerces = JSON.parse(JSON.stringify(this.initial))
      this.update = []
    },
    openSidebar(data) {
      this.sidebarData = data
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.subscribeSidebar = !this.subscribeSidebar
    },
    getQueryParams() {
      let params = `?id_type=1`
      return params
    },
  },
  watch: {
    update: function() {
      // console.log('update watcher', this.update)
      this.update.length > 0 ? this.enableSave = false : this.enableSave = true
    }
  },
  created() {
    if(!moduleDirectPayment.isRegistered) {
      this.$store.registerModule('directPaymentModule', moduleDirectPayment)
      moduleDirectPayment.isRegistered = true
    }
    this.$store.dispatch(`directPaymentModule/getCommerces`, this.getQueryParams())
      .then(() => {
        this.commerces = this.$store.getters["directPaymentModule/getCommerces"]
      })
      .catch(error => {console.log(error)})
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      tr[warn="true"] {
        background-color: #d4d4d4
      }
      .tr-expand {
        td {
          .tr-expand--close {
            position: relative !important;
          }
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }

    .vs-con-input-label + .tr-expand--close {
      margin-top: 20px
    }
  }
}
</style>
